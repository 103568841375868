import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProfilePage from "../../components/members/profile"

const Myprofile = () => {
  return (
    <Layout>
      <SEO
        title="Profile"
        desc="Profile"
        pathname="/profile"
        keywords="kyts profile"
      />
      <ProfilePage />
    </Layout>
  )
}

export default Myprofile

